'use strict';

/**
 * @name purchaseRequisitionV2AdvancedSearchCtrl
 *
 * @description Controller for Purchase Requisition v2 advanced search
 *
 * @author Khoo Jay Shen <khoojs@sunway.com.my>
 * @copyright Sunway Metacloud 2023
 */
function purchaseRequisitionV2AdvancedSearchCtrl($scope, searchModule, listChild, toastr, $rootScope,advancedSearch,
                                               exportSearch, pathConstants, $http, billingFromCompanies, companyExpenseTypes, $state, $filter) {

  // columns listing
  $scope.columns = [
    {id: 'header_info|custom_reference', label: 'PR#', selected: true},
    {id: 'priceComparison_number|pc_number', label: 'PC#', selected: true, unsortable: true,},
    {id: 'header_info|title', label: 'Title', selected: true},
    {
      id: 'grand_total_pr_currency',
      label: 'Amount',
      type: 'double',
      unsearchable: true, selected: true
    },
    {id: 'closed_at', label: 'Approved Date',
      condition: ['approved'], unsearchable: true, selected: true},
    {
      id: 'submitted_at',
      label: 'Submitted Date',
      condition: ['pending', 'approved'],
      type: 'date',
      unsearchable: true, selected: true
    },
    {
      id: 'header_info|needed_by_date',
      label: 'Needed By',
      type: 'date',
      unsearchable: true, selected: true
    },
    {id: 'creator_info|display_name', label: 'Creator', selected: true},
    {id: 'requestor|display_name', label: 'Created on behalf', selected: true},
    {
      id: 'created_at',
      label: 'Created At',
      type: 'date',
      unsearchable: true, selected: true
    },
    {
      id: 'updated_at',
      label: 'Updated At',
      type: 'date',
      unsearchable: true, selected: true
    },
    {id: 'header_info|company|descr', label: 'Company', selected: true},
    {id: 'header_info|cost_center|code', label: 'Cost Center', selected: true},
    {
      id: 'marked_as_deleted_at',
      label: 'Deleted Date',
      condition: ['deleted'],
      type: 'date',
      unsearchable: true, selected: true
    },
    {
      id: 'pr_items',
      label: 'Line items',
      condition: ['pending', 'approved'],
      unsortable: true,
      unsearchable: true, selected: true
    },
    {
      id: 'header_info|status',
      label: 'Status',
      unsearchable: true, selected: true
    },
    {
      id: 'progress',
      label: 'Progress',
      condition: ['pending', 'approved'],
      unsortable: true,
      unsearchable: true, selected: true
    },
    {
      id: 'waiting_on',
      label: 'Waiting on',
      condition: ['pending'],
      unsortable: true,
      unsearchable: true, selected: true
    },
    {id: 'pos', label: 'PO#',
      condition: ['approved'],
      unsortable: true,
      unsearchable: true,
      selected: true},
  ];

  
  $scope.datePickerTimezone = $rootScope.datePickerTimezone;
  $scope.datepickerOpened = {
    neededByDateFrom: false,
    neededByDateTo: false,
    prFullyApprovedDateFrom: false,
    prFullyApprovedDateTo: false,
    prCreatedDateFrom: false,
    prCreatedDateTo: false,
    submissionDateFrom: false,
    submissionDateTo: false,
  };
  var dateOptions = {
    formatYear: 'yy',
    startingDay: 1
  };
  $scope.datePickerOptions = {
    neededByDateFrom: _.clone(dateOptions),
    neededByDateTo: _.clone(dateOptions),
    prFullyApprovedDateFrom: _.clone(dateOptions),
    prFullyApprovedDateTo: _.clone(dateOptions),
    prCreatedDateFrom: _.clone(dateOptions),
    prCreatedDateTo: _.clone(dateOptions),
    submissionDateFrom: _.clone(dateOptions),
    submissionDateTo: _.clone(dateOptions)
  };

  $scope.errors = {};
  var toastrErrorMessage = null;
  $scope.resetForm = resetForm;
  $scope.viewPrV2Detail = viewPrV2Detail;
  $scope.showColumn = showColumn;
  $scope.submitAdvSearch = submitAdvSearch;
  $scope.openDatePicker = openDatePicker;
  $scope.loadExpenseType = loadExpenseType;
  
  // $scope.checkIfDisabled = checkIfDisabled;
  $scope.validateAmountRange = validateAmountRange;
  $scope.hasError = hasError;
  
  // variables for currency
  $scope.currencyData = [];

  // variables for mode of purchase
  $scope.modeOfPurchaseList = [];

  // variables for search users
  $scope.searchUsers = searchUsers;
  $scope.userSearching = false;
  $scope.userListing = [];

  // variables for ERP Budget ID
  $scope.erpBudgetIdSearching = false;
  $scope.budgetListing = [];
  $scope.searchErpBudgetId = searchErpBudgetId;

  // variables for item name
  $scope.itemNameListing = [];
  $scope.searchItemName = searchItemName;
  $scope.itemNameSearching = false;
  $scope.updateItemName = updateItemName;  

  // variables for item code
  $scope.itemCodeListing = [];
  $scope.searchItemCode = searchItemCode;
  $scope.itemCodeSearching = false;
  $scope.updateItemCode = updateItemCode;

  // variables for expense type
  $scope.expenseTypeList = [];

  // variables for company and billing company 
  $scope.companySearching = false;
  $scope.companyList = [];
  $scope.searchCompany = searchCompany;
  $scope.onSelectBillingCompany = onSelectBillingCompany;
  $scope.selectedBilingCompany = null;
  $scope.searchExpenseTypeBasedOnCompany = searchExpenseTypeBasedOnCompany;

  // variables for cost centers
  $scope.costCenterSearching = false;
  $scope.costCenterListing = [];
  $scope.searchCostCenter = searchCostCenter;
  $scope.searchCostCenterBasedOnCompany = searchCostCenterBasedOnCompany;

  $scope.disablePoPrRange = disablePoPrRange;
  $scope.disableWaitingOn = disableWaitingOn;
  $scope.disableAssets = disableAssets;

  // variables for PC Numbers
  $scope.pcSearching = false;
  $scope.pcList = [];
  $scope.searchPcNumber = searchPcNumber;

  // variable for tender
  $scope.searchTenders = searchTenders;

  // variable for Item Category
  $scope.itemCategoryListing = itemCategoryListing;

  // variable for Asset Category
  $scope.searchAssetCategories = searchAssetCategories;

  // variable for Asset Main Number
  $scope.searchAssetNumber = searchAssetNumber;

  // variable for GL Account Code
  $scope.searchAccountCodes = searchAccountCodes;

  // varialbe for Project Code
  $scope.searchProjectCode = searchProjectCode;
  $scope.onSelectProjectCode = onSelectProjectCode;

  // assigning values for these dropdown lists (PR Status/ PR No Pricing Available/ PR No Expiry)
  $scope.prNoExpiryOptions = [
    {
      id: 1,
      key: null,
      value: 'All'
    },
    {
      id: 2,
      key: true,
      value: 'Yes'
    },
    {
      id: 3,
      key: false,
      value: 'No'
    }
  ];

  $scope.prNoPricingAvailableOptions = [
    {
      id: 1,
      key: null,
      value: 'All'
    },
    {
      id: 2,
      key: true,
      value: 'Yes'
    },
    {
      id: 3,
      key: false,
      value: 'No'
    }
  ];

  $scope.prStatusOptions = [
    {
      id: 1,
      key: 'all',
      value: 'All'
    },
    {
      id: 2,
      key: 'draft',
      value: 'Draft'
    },
    {
      id: 3,
      key: 'pending',
      value: 'Pending Approval'
    },
    {
      id: 4,
      key: 'approved',
      value: 'Approved'
    },
    {
      id: 5,
      key: 'deleted',
      value: 'Deleted'
    },
    {
      id: 6,
      key: 'withdrawn',
      value: 'Withdrawn'
    },
    {
      id: 7,
      key: 'rejected',
      value: 'Rejected'
    }
  ];


  // this is to store all the fields value
  var fields = {
    billingCompany: null,
    company: null,
    costCenter: null,
    expenseType: {},
    prNumberFrom: null,
    prNumberTo: null,
    poNumberFrom: null,
    poNumberTo: null,
    totalPriceFrom: null,
    totalPriceTo: null,
    totalCostOfPrFrom: null,
    totalCostOfPrTo: null,
    prTitle: null,
    prStatus: {selected: $scope.prStatusOptions[0]},
    creator: null,
    createdOnBehalf: null,
    modeOfPurchase: {},
    itemTitle: null,
    itemCode: null,
    itemCategory: null,
    assetCategories: null,
    assetNumber: null,
    accountCode: null,
    projects: null,
    supplier: null,
    erpBudgetId: null,
    approvedBy: null,
    waitingOn: null,
    tender: null,
    currency: {},
    prNoExpiry: {selected: $scope.prNoExpiryOptions[0]},
    prNoPricingAvailable: {selected: $scope.prNoPricingAvailableOptions[0]},
    submissionDateFrom: null,
    submissionDateTo: null,
    neededByDateFrom: null,
    neededByDateTo: null,
    prFullyApprovedDateFrom: null,
    prFullyApprovedDateTo: null,
    prCreatedDateFrom: null,
    prCreatedDateTo: null,
    pcNumber: null
  };


  // this can be used in reset form
  $scope.fields = angular.copy(fields);
  // angular.copy is used to prevent the original declare variables from being overide

  /**
   * function to reset the search form
   */
  function resetForm() {
    $scope.fields = angular.copy(fields);
    loadExpenseType();
    // to check later
    $scope.errors = {};
    $scope.dataList = [];
    $scope.companyList = [];
    $scope.costCenterListing = [];
  }

  // load currencies
  searchModule.get(
    {
      module: 'currencies',
      'criteria[0][is_active]': 1,
      offset: 0
    },
    function(resource) {
      $scope.currencyData = resource.content.data;
    }
  );

  // load mode of purchase
  searchModule.get(
    {
      module: 'mode-of-purchase',
      'criteria[0][is_active]': true,
      offset: 0
    },
    function(resource) {
      $scope.modeOfPurchaseList = resource.content.data;
    }
  );

  // load expense type
  function loadExpenseType() {
    searchModule.get(
    {
      module: 'expense-type',
      'criteria[0][is_active]': 1,
      offset: 0
    },
    function(resource) {
      $scope.expenseTypeList = resource.content.data;
    }
  );
  }
  
  /**
   * function to search and select expense type based on company
   * @param company
   */
  function searchExpenseTypeBasedOnCompany(company) {
    $scope.expenseTypeList = [];
    companyExpenseTypes.get(
      {
        id: company._id
      },
      function (resource) {
        if (!!resource.content && !!resource.content.data) {
          $scope.expenseTypeList = resource.content.data;
        }
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    )  
  }

  // clear either company or cost center and reset expense type depending on the company field
  $scope.clearCompanyOrCostCenter = function (value) {
    if (value.length === 0) {
      $scope.company = null;
      $scope.fields['costCenter'] = null;
      $scope.fields['expenseType'] = {};
      loadExpenseType();
    }
  };

  /**
   * function to search users (approved by, waiting on, creator, created on behalf)
   * @param name
   */
  function searchUsers(name) {
    if (!!name && name.length > 2) {
      $scope.userSearching = true;
      return $http.get(pathConstants.apiUrls.genericSearch.searchBasePath + '/users', {
        params: {
          module: 'users',
          'criteria[1][display_name]': name,
          criteria_operator: 'and'
        }
      }).then(function (response) {
        $scope.userSearching = false;
        $scope.userListing = response.data.content.data;
        return response.data.content.data;
      }, function (error) {
        $scope.userSearching = false;
        globalFunc.objectErrorMessage(error);
      });
    }
  }

  /**
   * function to search and select ERP Budget ID
   * @param id
   */
  function searchErpBudgetId(id) {
    if (!!id && id.length > 2) {
      $scope.erpBudgetIdSearching = true;
      return $http.get(pathConstants.apiUrls.genericSearch.searchBasePath + '/budgets', {
        params: {
          module: 'budgets',
          'criteria[0][erp_budget_id]': id,
          'criteria[0][$operator]': 'or',
          criteria_operator: 'and',
        }
      }).then(function (response) {
        $scope.erpBudgetIdSearching = false;
        $scope.budgetListing = response.data.content.data;
        return response.data.content.data;
      }, function (error) {
        $scope.userSearching = false;
        globalFunc.objectErrorMessage(error);
      });
    }
  }

  /**
   * function to search and select Item Name
   * @param itemName
   */
  function searchItemName(itemName) {
    if (!!itemName && itemName.length > 2) {
      $scope.itemNameSearching = true;
      return $http.get(pathConstants.apiUrls.genericSearch.searchBasePath + '/item-master', {
        params: {
          module: 'item-master',
          'criteria[0][name]': itemName,
          'criteria[0][$operator]': 'or',
          criteria_operator: 'and',
        }
      }).then(function (response) {
        $scope.itemNameSearching = false;
        $scope.itemNameListing = response.data.content.data;
        return response.data.content.data;
      }, function (error) {
        $scope.itemNameSearching = false;
        globalFunc.objectErrorMessage(error);
      });
    }
  }

  /**
   * function to search and select Item Code
   * @param itemCode
   */
  function searchItemCode(itemCode) {
    if (!!itemCode && itemCode.length > 2) {
      $scope.itemCodeSearching = true;
      return $http.get(pathConstants.apiUrls.genericSearch.searchBasePath + '/item-master', {
        params: {
          module: 'item-master',
          'criteria[0][code]': itemCode,
          'criteria[0][$operator]': 'or',
          criteria_operator: 'and',
        }
      }).then(function (response) {
        $scope.itemCodeSearching = false;
        $scope.itemCodeListing = response.data.content.data;
        return response.data.content.data;
      }, function (error) {
        $scope.itemCodeSearching = false;
        globalFunc.objectErrorMessage(error);
      });
    }
  }

  /**
   * function to search and select Item Category
   * @param itemCategory
   */
  function itemCategoryListing(itemCategory){
    var moduleParam = {
      module: 'item-category'
    };
    var additionalParams = {
      'criteria[0][is_active]': true,
      'criteria[1][name]': itemCategory,
      criteria_operator: 'and',
      offset: 10
    };
    return $http.get($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam), {
      params: additionalParams,
      ignoreLoadingBar: true
    }).then(function (response) {
      if (response.data.content.data.length === 0) {
        return [];
      } else {
        return response.data.content.data.map(function (item) {
          return item;
        });
      }
    });
  }

  /**
   * function to search and select Asset Category
   * @param assetCategories
   * @param companyCode
   */
  function searchAssetCategories(assetCategories, companyCode){
    var moduleParam = {
      module: 'asset-category'
    };

    var params = {
      'criteria[0][code]': assetCategories,
      'criteria[0][name]': assetCategories,
      'criteria[0][$operator]': 'or',
      'criteria[1][company|code]': companyCode.code,
      'criteria[1][$strict]': true,
      'criteria[2][is_active]': true,
      criteria_operator: 'and',
      offset: 10
    };

    return $http.get($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam), {
        params: params
      }).then(function (response) {
      if (response.data.content.data.length === 0) {
        return [];
      } else {
        return response.data.content.data.map(function (item) {
          return item;
        });
      }
    });
  }

  /**
   * function to search and select Asset Number
   * @param assetNumber
   * @param companyCode
   */
  function searchAssetNumber(assetNumber, companyCode){
    var moduleParam = {
      module: 'company-asset'
    };

    var params = {
      'criteria[0][asset_main_number]': assetNumber,
      'criteria[0][name]': assetNumber,
      'criteria[0][$operator]': 'or',
      'criteria[1][company|code]': companyCode.code,
      'criteria[2][is_active]': true,
      criteria_operator: 'and',
      offset: 10
    };

    return $http.get($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam), {
        params: params
      }).then(function (response) {
      if (response.data.content.data.length === 0) {
        return [];
      } else {
        return response.data.content.data.map(function (item) {
          return item;
        });
      }
    });
  }

  /**
   * function to search and select GL Account Code
   * @param accountCode
   * @param companyCode
   */
  function searchAccountCodes(accountCode, companyCode) {
    var moduleParam = {
      module: 'account-code'
    };

    var additionalParams = {
            'criteria[1][name]': accountCode,
            'criteria[1][descr]': accountCode,
            'criteria[1][code]': accountCode,
            'criteria[1][$operator]': 'or',
            'criteria[0][company_code]': companyCode.code,
            limit: 5
          }

    return $http.get($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam), {
      params: additionalParams
    }).then(function (response) {
      if (response.data.content.data.length === 0) {
        return [];
      } else {
        return response.data.content.data.map(function (item) {
          return item
        });
      }
    });
  }

  /**
   * function to search and select GL Account Code
   * @param projects
   * @param companyCode
   */
  function searchProjectCode(projects, companyCode){
    var moduleParam = {
      module: 'project'
    };

    var additionalParams = {
      'criteria[0][code]': projects,
      'criteria[0][name]': projects,
      'criteria[0][$operator]': 'or',
      'criteria[1][company|code]': companyCode.code,
      'criteria[1][$operator]':'and',
      'criteria[2][is_active]': true,
      criteria_operator: 'and',
      offset: 100
    };

    return $http.get($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam), {
      params: additionalParams
    }).then(function (response) {
      if (response.data.content.data.length === 0) {
        return [];
      } else {
        return response.data.content.data.map(function (item) {
          return item
        });
      }
    });
  }

  /**
   * function to search and select company
   * @param company
   */
  function searchCompany(company) {
    // clear the dropdown list
    $scope.companyList = [];
    $scope.costCenterListing = [];
    $scope.accountCodeListing = [];

    if (!!company && company.length > 2) {
      $scope.companySearching = true;
      return $http.get(pathConstants.apiUrls.genericSearch.searchBasePath + '/companies', {
        params: {
          module: 'companies',
          'criteria[0][descr]': company,
          'criteria[0][code]': company,
          'criteria[0][$operator]': 'or',
          criteria_operator: 'and',
          offset: 0,
        }
      }).then(function (response) {
        $scope.companySearching = false;
        $scope.companyList = response.data.content.data;
        return response.data.content.data;
      }, function (error) {
        $scope.companySearching = false;
        globalFunc.objectErrorMessage(error);
      });
    }
  }

  /**
   * function to search and select company
   * @param company
   */
  function searchPcNumber(pc) {
    // clear the dropdown list
    $scope.pcList = [];
    if (!!pc && pc.length > 2) {
      $scope.pcSearching = true;
      return $http.get(pathConstants.apiUrls.genericSearch.searchBasePath + '/price-comparison', {
        params: {
          module: 'price-comparison',
          'criteria[0][pc_number]': pc,
          'criteria[0][pr_info|company|code]': $scope.fields.company.code,
          'criteria[0][$operator]': 'and',
          criteria_operator: 'and',
          offset: 0,
        }
      }).then(function (response) {
        $scope.pcSearching = false;
        $scope.pcList = response.data.content.data;
        return response.data.content.data;
      }, function (error) {
        $scope.pcSearching = false;
        globalFunc.objectErrorMessage(error);
      });
    }
  }

  /**
   * typeahead for tender search
   * @param val
   * @returns {*}
   */
  function searchTenders(val) {
    if (!!val && val.length > 2) {
      $scope.tenderSearching = true;

      var moduleParam = {
        module: 'tender'
      };

      var additionalParams = {
        'criteria[0][code]': val,
        'criteria[0][detail|name]': val,
        'criteria[0][$operator]': 'or',
        'criteria[1][detail|status][0]': 'APPROVED',
        'criteria[1][detail|status][1]': 'DEACTIVATED',
        'criteria[1][detail|status][2]': 'EXPIRED',
        criteria_operator: 'and',
        offset: 10
      };

      return $http.get($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam), {
        params: additionalParams
      }).then(function (response) {
        return response.data.content.data.map(function (item) {
          return item;
        });
      }, function (error) {
        globalFunc.objectErrorMessage(error);
      });

    }
  }

  /**
   * function to search and select cost centers
   * @param costCenter
   */
  function searchCostCenter(costCenter) {
    $scope.costCenterListing = [];
    if (!!costCenter && costCenter.length > 2) {
      $scope.costCenterSearching = true;
      return $http.get(pathConstants.apiUrls.genericSearch.searchBasePath + '/cost-center', {
        params: {
          module: 'cost-center',
          'criteria[0][descr]': costCenter,
          'criteria[0][code]': costCenter,
          'criteria[0][$operator]': 'or',
          criteria_operator: 'and',
        }
      }).then(function (response) {
        $scope.costCenterSearching = false;
        $scope.costCenterListing = response.data.content.data;
        return response.data.content.data;
      }, function (error) {
        $scope.costCenterSearching = false;
        globalFunc.objectErrorMessage(error);
      });
    }
  }

  /**
   * function to search and select cost centers based on company
   * @param company
   */
  function searchCostCenterBasedOnCompany(company) {
    $scope.fields['costCenter'] = null;
    if (company._id) {
      $scope.costCenterSearching = true;
      return $http.get(pathConstants.apiUrls.genericSearch.searchBasePath + '/cost-center', {
        params: {
          module: 'cost-center',
          'criteria[0][company_id]': company._id,
          'criteria[0][$operator]': 'or',
          criteria_operator: 'and',
          offset: 0,
        }
      }).then(function (response) {
        $scope.costCenterSearching = false;
        $scope.costCenterListing = response.data.content.data;
        return response.data.content.data;
      }, function (error) {
        $scope.costCenterSearching = false;
        globalFunc.objectErrorMessage(error);
      });
    }
  }

  // function to update item code field if item name is entered
  function updateItemCode() {
    if (!!$scope.fields['itemTitle']) {
      $scope.fields['itemCode'] = $scope.fields['itemTitle'];
    }
  }

  // function to update item name field if item code is entered
  function updateItemName() {
    if (!!$scope.fields['itemCode']) {
      $scope.fields['itemTitle'] = $scope.fields['itemCode'];
    }
  }

  // function to disable PO/PR range and PC Number depending on company field
  function disablePoPrRange() {
    if ($scope.fields['company'] === null || $scope.fields['company'] === '') {
      $scope.fields['poNumberFrom'] = null;
      $scope.fields['poNumberTo'] = null;
      $scope.fields['prNumberFrom'] = null;
      $scope.fields['prNumberTo'] = null;
      $scope.fields['pcNumber'] = null;
      return true;
    }
    else {
      return false;
    }
  }

  // function to disable Asset Category and Asset Number depending on company field
  function disableAssets() {
    if ($scope.fields['company'] === null || $scope.fields['company'] === '') {
      $scope.fields['assetCategories'] = null;
      $scope.fields['assetNumber'] = null;
      $scope.fields['accountCode'] = null;
      $scope.fields['projects'] = null;
      return true;
    }
    else {
      return false;
    }
  }

  // function to update asset category field if project code is selected
  function onSelectProjectCode(projectCode) {
    if (!!$scope.fields['projects']) {
      $scope.fields['assetCategories'] = projectCode.asset_category;
    }
  }

  // to show dropdown list for company once billing company is selected
  function onSelectBillingCompany(company) {
    $scope.selectedBilingCompany = company._id;
    //clear function
    $scope.costCenterListing = [];
    $scope.companyList = [];
    $scope.company = null;
    billingFromCompanies.get({
      code: company.code
    }, function (resource) {
      if (!!resource.content && !!resource.content.data) {
        if (resource.content.data.length < 1)
          toastr.warning('No company bills to ' + company.descr); //warning to show the users no options will be added

        _.map(resource.content.data, function (company) {
             $scope.companyList.push(company);
        });
      }
    });
  }

  // to grey out and clear waiting on field depending on po number range or pr status
  function disableWaitingOn() {
    if (!!$scope.fields['poNumberFrom'] || !!$scope.fields['poNumberTo']) {
      $scope.fields['waitingOn'] = null;
      return true;
    }
    else if (!!$scope.fields['prStatus']) {
      if ($scope.fields['prStatus'].selected.key === 'pending' || $scope.fields['prStatus'].selected.key === 'withdrawn' || $scope.fields['prStatus'].selected.key === 'all') {
        return false;
      }
      else {
        $scope.fields['waitingOn'] = null;
        return true;
      }
    }
  }

  /**
   * function to set datepicker fields' options.
   * set minDate/maxDate if the related date range field is already set and remove error message
   *
   * @param $event
   * @param field
   */
  function openDatePicker($event, field) {
    $event.preventDefault();
    $event.stopPropagation();

    if (field === 'neededByDateFrom') {
      $scope.datePickerOptions.neededByDateFrom.maxDate = $scope.fields['neededByDateTo'] || null;
      $scope.datepickerOpened.neededByDateFrom = true;
      delete $scope.errors.neededByDate;
    } else if (field === 'neededByDateTo') {
      $scope.datePickerOptions.neededByDateTo.minDate = $scope.fields['neededByDateFrom'] || null;
      $scope.datepickerOpened.neededByDateTo = true;
      delete $scope.errors.neededByDate;
    } else if (field === 'prFullyApprovedDateFrom') {
      $scope.datePickerOptions.prFullyApprovedDateFrom.maxDate = $scope.fields['prFullyApprovedDateTo'] || null;
      $scope.datepickerOpened.prFullyApprovedDateFrom = true;
      delete $scope.errors.prFullyApprovedDate;
    } else if (field === 'prFullyApprovedDateTo') {
      $scope.datePickerOptions.prFullyApprovedDateTo.minDate = $scope.fields['prFullyApprovedDateFrom'] || null;
      $scope.datepickerOpened.prFullyApprovedDateTo = true;
      delete $scope.errors.prFullyApprovedDate;
    } else if (field === 'prCreatedDateFrom') {
      $scope.datePickerOptions.prCreatedDateFrom.maxDate = $scope.fields['prCreatedDateTo'] || null;
      $scope.datepickerOpened.prCreatedDateFrom = true;
      delete $scope.errors.prCreatedDate;
    } else if (field === 'prCreatedDateTo') {
      $scope.datePickerOptions.prCreatedDateTo.minDate = $scope.fields['prCreatedDateFrom'] || null;
      $scope.datepickerOpened.prCreatedDateTo = true;
      delete $scope.errors.prCreatedDate;
    } else if (field === 'submissionDateFrom') {
      $scope.datePickerOptions.submissionDateFrom.maxDate = $scope.fields['submissionDateFrom'] || null;
      $scope.datepickerOpened.submissionDateFrom = true;
      delete $scope.errors.submissionDate;
    } else if (field === 'submissionDateTo') {
      $scope.datePickerOptions.submissionDateTo.minDate = $scope.fields['submissionDateTo'] || null;
      $scope.datepickerOpened.submissionDateTo = true;
      delete $scope.errors.submissionDate;
    }
  }

  /**
   * function to submit search form
   */
  function submitAdvSearch() {
    var searchParam = validateForm();

    if (!!searchParam) {
      $scope.embeddedParams = searchParam;

      /**
       * Setting the params to prepare for exporting
       */
      exportSearch.setParams(searchParam);

      advancedSearch.get(
        searchParam,
        function (resource) {
          $scope.dataList = resource.content.data;
          $scope.meta = resource.content.meta;
          $scope.emptyInput = false;
        },
        function (error) {
          toastr.error('Something went wrong');
          $scope.dataList = [];
        }
      );
    } else {
      toastr.error(toastrErrorMessage);
      toastrErrorMessage = null;
      $scope.emptyInput = true;
    }

    $('#show-search-form').removeClass('hidden');
    $('#hide-search-form').addClass('hidden');
  }

  /**
   * function to check if a specific field has error.
   * @param field field name
   * @returns {boolean}
   */
  function hasError(field) {
    return (field in $scope.errors);
  }

  /**
   * function to validate amount range fields, and update/remove error message
   * @param field
   */
  function validateAmountRange(field) {
    if (field === 'totalPrice') {
      if (!!$scope.fields['totalPriceFrom'] && !!$scope.fields['totalPriceTo'] && ($scope.fields['totalPriceFrom'] > $scope.fields['totalPriceTo'])) {
        $scope.errors.totalPrice = 'Invalid Range';
      } else {
        if ('totalPrice' in $scope.errors) {
          delete $scope.errors.totalPrice;
        }
      }
    } else if (field === 'totalCostOfPr') {
      if (!!$scope.fields['totalCostOfPrFrom'] && !!$scope.fields['totalCostOfPrTo'] && ($scope.fields['totalCostOfPrFrom'] > $scope.fields['totalCostOfPrTo'])) {
        $scope.errors.totalCostOfPr = 'Invalid Range';
      } else {
        if ('totalCostOfPr' in $scope.errors) {
          delete $scope.errors.totalCostOfPr
        }
      }
    }
  }

  /**
   * function to validate form input values
   * @returns {boolean|Object}
   */
  function validateForm() {
    /**
     * logic for range fields (total price, total cost of pr, and all date fields)
     * if `from` field is not empty, set `to` field to same value as `from` value
     * if `to` field is not empty, `from` field is mandatory.
     * if both are empty, skip validation
     *
     */

    // total price range
    if (!!$scope.fields['totalPriceTo']) {
      if (!!$scope.fields['totalPriceFrom']) {
        if ($scope.fields['totalPriceFrom'] > $scope.fields['totalPriceTo']) {
          $scope.errors.totalPrice = 'Invalid Range';
        }
      } else {
        $scope.errors.totalPrice = '\'From\' field is mandatory';
      }
    } else if (!!$scope.fields['totalPriceFrom']) {
      $scope.fields['totalPriceTo'] = $scope.fields['totalPriceFrom'];
    }

    // total cost of pr range
    if (!!$scope.fields['totalCostOfPrTo']) {
      if (!!$scope.fields['totalCostOfPrFrom']) {
        if ($scope.fields['totalCostOfPrFrom'] > $scope.fields['totalCostOfPrTo']) {
          $scope.errors.totalCostOfPr = 'Invalid Range';
        }
      } else {
        $scope.errors.totalCostOfPr = '\'From\' field is mandatory';
      }
    } else if (!!$scope.fields['totalCostOfPrFrom']) {
      $scope.fields['totalCostOfPrTo'] = $scope.fields['totalCostOfPrFrom'];
    }

    //submission date range
    if (!!$scope.fields['submissionDateTo']) {
      if (!!$scope.fields['submissionDateFrom']) {
        if ($scope.fields['submissionDateFrom'] > $scope.fields['submissionDateTo']) {
          $scope.errors.submissionDate = 'Invalid Range';
        }
      } else {
        $scope.errors.submissionDate = '\'From\' field is mandatory';
      }
    } else if (!!$scope.fields['submissionDateFrom']) {
      $scope.fields['submissionDateTo'] = $scope.fields['submissionDateFrom'];
    }

    //needed by date range
    if (!!$scope.fields['neededByDateTo']) {
      if (!!$scope.fields['neededByDateFrom']) {
        if ($scope.fields['neededByDateFrom'] > $scope.fields['neededByDateTo']) {
          $scope.errors.neededByDate = 'Invalid Range';
        }
      } else {
        $scope.errors.neededByDate = '\'From\' field is mandatory';
      }
    } else if (!!$scope.fields['neededByDateFrom']) {
      $scope.fields['neededByDateTo'] = $scope.fields['neededByDateFrom'];
    }

    //pr fully approved date range
    if (!!$scope.fields['prFullyApprovedDateTo']) {
      if (!!$scope.fields['prFullyApprovedDateFrom']) {
        if ($scope.fields['prFullyApprovedDateFrom'] > $scope.fields['prFullyApprovedDateTo']) {
          $scope.errors.prFullyApprovedDate = 'Invalid Range';
        }
      } else {
        $scope.errors.prFullyApprovedDate = '\'From\' field is mandatory';
      }
    } else if (!!$scope.fields['prFullyApprovedDateFrom']) {
      $scope.fields['prFullyApprovedDateTo'] = $scope.fields['prFullyApprovedDateFrom'];
    }

    // pr created date range
    if (!!$scope.fields['prCreatedDateTo']) {
      if (!!$scope.fields['prCreatedDateFrom']) {
        if ($scope.fields['prCreatedDateFrom'] > $scope.fields['prCreatedDateTo']) {
          $scope.errors.prCreatedDate = 'Invalid Range';
        }
      } else {
        $scope.errors.prCreatedDate = '\'From\' field is mandatory';
      }
    } else if (!!$scope.fields['prCreatedDateFrom']) {
      $scope.fields['prCreatedDateTo'] = $scope.fields['prCreatedDateFrom'];
    }
    if (_.isEmpty($scope.errors)) {
      return buildParam($scope.fields);
    } else {
      toastrErrorMessage = 'Invalid value in one or more fields';
      return false;
    }
  }

  /**
   * function to build criteria parameters
   * @param data object of form input values
   * @returns {boolean|Object}
   */
  function buildParam(data) {
    var params = {};

    if (!!data.billingCompany) {
      params['criteria[0][header_info|shadow_company|code]'] = data.billingCompany.code;
    }

    if (!!data.company) {
      params['criteria[0][header_info|company|code]'] = data.company.code;
    }

    if (!!data.costCenter) {
      params['criteria[0][header_info|cost_center|code]'] = data.costCenter.code;
    }

    if (!!data.expenseType.selected) {
      params['criteria[0][header_info|expense_type|_id]'] = data.expenseType.selected._id;
    }

    // pr number range
    if (!!data.prNumberFrom) {
      params['criteria[0][header_info|numerical_reference_counter][from]'] = data.prNumberFrom;
      params['criteria[0][header_info|numerical_reference_counter][to]'] = data.prNumberTo;
    }

    // po number range
    if (!!data.poNumberFrom) {
      params['criteria[0][pr_items|po_number][from]'] = data.poNumberFrom;
      params['criteria[0][pr_items|po_number][to]'] = data.poNumberTo;
    }

    if (!!data.totalPriceFrom) {
      params['criteria[0][pr_items|pr_line_item_total_amount_pr_currency_inclusive_of_other_charges][from]'] = data.totalPriceFrom;
      params['criteria[0][pr_items|pr_line_item_total_amount_pr_currency_inclusive_of_other_charges][to]'] = data.totalPriceTo;
    }

    if (!!data.totalCostOfPrFrom) {
      params['criteria[0][grand_total_pr_currency][from]'] = data.totalCostOfPrFrom;
      params['criteria[0][grand_total_pr_currency][to]'] = data.totalCostOfPrTo;
    }

    if (!!data.prTitle) {
      params['criteria[0][header_info|title]'] = data.prTitle;
    }

    if (!!data.prStatus.selected) {
      if (data.prStatus.selected.key !== 'all') {
        params['criteria[0][header_info|status]'] = data.prStatus.selected.key;
      }
    }

    if (!!data.creator) {
      params['criteria[0][creator_info|_id]'] = data.creator._id;
    }

    if (!!data.createdOnBehalf) {
      params['criteria[0][requestor|_id]'] = data.createdOnBehalf._id;
    }

    if (!!data.modeOfPurchase.selected) {
      params['criteria[0][header_info|mode_of_purchase|_id]'] = data.modeOfPurchase.selected._id;
    }

    if (!!data.itemTitle) {
      params['criteria[0][pr_items|name]'] = data.itemTitle.name;
    }

    if (!!data.itemCode) {
      params['criteria[0][pr_items|item_code]'] = data.itemCode.code;
    }

    if (!!data.itemCategory) {
      params['criteria[0][pr_items|category|code]'] = data.itemCategory.code;
    }

    if (!!data.assetCategories) {
      params['criteria[0][pr_items|cerf|asset_category|code]'] = data.assetCategories.code;
    }

    if (!!data.assetNumber) {
      params['criteria[0][pr_items|cerf|asset_category|code]'] = data.assetNumber.code;
    }

    if (!!data.accountCode) {
      params['criteria[0][pr_items|account_assignments|account_code|_id]'] = data.accountCode._id;
    }

    if (!!data.projects) {
      params['criteria[0][pr_items|project_code|code]'] = data.projects.code;
    } 

    if (!!data.supplier) {
      params['criteria[0][pr_items|supplier|_id]'] = data.supplier._id;
    }

    if (!!data.erpBudgetId) {
      params['criteria[0][pr_items|budget|erp_budget_id]'] = data.erpBudgetId.erp_budget_id;
    }

    if (!!data.approvedBy) {
      params['criteria[0][approvers_info_latest|_id]'] = data.approvedBy._id;
    }

    if (!!data.waitingOn) {
      params['criteria[0][waiting_on]'] = data.waitingOn._id;
    }

    if (!!data.tender) {
      params['criteria[0][header_info|tender_id]'] = data.tender._id;
    }

    if (!!data.currency.selected) {
      params['criteria[0][header_info|currency|_id]'] = data.currency.selected._id;
    }

    if (!!data.prNoExpiry.selected) {
      if (data.prNoExpiry.selected.key !== null) {
        params['criteria[0][header_info|pr_no_expiry]'] = data.prNoExpiry.selected.key;
      }
    }

    if (!!data.prNoPricingAvailable.selected) {
      if (data.prNoPricingAvailable.selected.key !== null) {
        params['criteria[0][header_info|no_pricing_available]'] = data.prNoPricingAvailable.selected.key;
      }
    }

    if (!!data.neededByDateFrom) {
      params['criteria[0][header_info|needed_by_date][from]'] = moment(data.neededByDateFrom).valueOf();
      params['criteria[0][header_info|needed_by_date][to]'] = moment(data.neededByDateTo).endOf('day').valueOf();
    }

    if (!!data.submissionDateFrom) {
      params['criteria[0][submitted_at][from]'] = moment(data.submissionDateFrom).valueOf();
      params['criteria[0][submitted_at][to]'] = moment(data.submissionDateTo).endOf('day').valueOf();
    }

    if (!!data.prCreatedDateFrom) {
      params['criteria[0][created_at][from]'] = moment(data.prCreatedDateFrom).valueOf();
      params['criteria[0][created_at][to]'] = moment(data.prCreatedDateTo).endOf('day').valueOf();
    }

    if (!!data.prFullyApprovedDateFrom) {
      params['criteria[0][approved_at][from]'] = moment(data.prFullyApprovedDateFrom).valueOf();
      params['criteria[0][approved_at][to]'] = moment(data.prFullyApprovedDateTo).endOf('day').valueOf();
    }

    if (!!data.pcNumber) {
      params['criteria[0][pc_id]'] = data.pcNumber._id;
    }

      params['module'] = 'requisition';
      params['offset'] = 50;
      params['criteria[0][$operator]'] = 'and';
      return params;
  }

  /**
   * Function to navigate to budget details by id state
   */
  function viewPrV2Detail(data) {
    $state.go('main.user.purchaseRequisitionsV2.details' , {id : data._id});
  }

  /**
   * Function to display selected available columns
   */
  function showColumn(id) {
    var i;
    var columns = $scope.columns;
    for (i in columns) {
      if(columns.hasOwnProperty(i)) {
        if (id === columns[i].id) {
          if(!!columns[i].conditional) {
            if($scope.status === columns[i].condition)
              return columns[i].selected;
            if(columns[i].condition instanceof Array) {
              var j;
              var columnCondition = $scope.columns[i].condition;
              for(j in columnCondition) {
                if(columnCondition.hasOwnProperty(j)) {
                  if($scope.status === columnCondition[j]) {
                    return columns[i].selected;
                  }
                }
              }
            }
          }
          else
            return columns[i].selected;
        }
      }
    }
  }

  function initialize() {
    // initialize pagination parameters
    $scope.dataList = [];
    $scope.embeddedParams = [];
    $scope.meta = {};
    $scope.searchModule = advancedSearch;
    loadExpenseType();
  }

  initialize();

}

purchaseRequisitionV2AdvancedSearchCtrl.$inject = [
  '$scope', 'searchModule', 'listChild', 'toastr', '$rootScope', 'advancedSearch', 
  'exportSearch', 'pathConstants', '$http', 'billingFromCompanies', 'companyExpenseTypes', '$state', '$filter'
];

angular.module('metabuyer')
  .controller('purchaseRequisitionV2AdvancedSearchCtrl', purchaseRequisitionV2AdvancedSearchCtrl);